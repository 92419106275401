"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Connection = void 0;
var Connection = (function () {
    function Connection(navigator) {
        this.navigator = navigator;
    }
    Connection.prototype.isOnline = function () {
        return this.navigator.onLine;
    };
    return Connection;
}());
exports.Connection = Connection;
