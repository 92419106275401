"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentSession = void 0;
var CurrentSession = (function () {
    function CurrentSession(device, sessionStorage, sessionsTable, uuidFactory) {
        this.device = device;
        this.sessionStorage = sessionStorage;
        this.sessionsTable = sessionsTable;
        this.uuidFactory = uuidFactory;
        this.UUID_KEY = 'bf_session_uuid';
        this.UDID_KEY = 'bf_session_udid';
        this.uuid = this.sessionStorage.getItem(this.UUID_KEY);
        if (this.uuid === null) {
            this.initSession();
        }
        else {
            var sessionDeviceUDID = this.sessionStorage.getItem(this.UDID_KEY);
            var hasDeviceUDIDChanged = sessionDeviceUDID !== this.device.getUDID();
            if (hasDeviceUDIDChanged) {
                this.initSession();
            }
        }
    }
    CurrentSession.prototype.getUUID = function () {
        return this.uuid;
    };
    CurrentSession.prototype.initSession = function () {
        var deviceUDID = this.device.getUDID();
        this.uuid = this.uuidFactory.create();
        this.sessionStorage.setItem(this.UUID_KEY, this.uuid);
        this.sessionStorage.setItem(this.UDID_KEY, deviceUDID);
        this.sessionsTable.add({
            uuid: this.uuid,
            udid: deviceUDID,
        });
    };
    return CurrentSession;
}());
exports.CurrentSession = CurrentSession;
