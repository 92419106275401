"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bugfender = void 0;
var log_1 = require("./types/log");
var issue_1 = require("./types/issue");
var http_client_1 = require("./http-client");
var Bugfender = (function () {
    function Bugfender(apiClient, device, overrideConsoleMethods, printToConsole, registerBrowserEventsHandler, registerErrorHandler, registerUIEventsHandler, processor, sdkOptions, sdkUserAgent, session, storage, userFeedback) {
        this.apiClient = apiClient;
        this.device = device;
        this.overrideConsoleMethods = overrideConsoleMethods;
        this.printToConsole = printToConsole;
        this.registerBrowserEventsHandler = registerBrowserEventsHandler;
        this.registerErrorHandler = registerErrorHandler;
        this.registerUIEventsHandler = registerUIEventsHandler;
        this.processor = processor;
        this.sdkOptions = sdkOptions;
        this.sdkUserAgent = sdkUserAgent;
        this.session = session;
        this.storage = storage;
        this.userFeedback = userFeedback;
        this.LogLevel = log_1.LogLevel;
    }
    Bugfender.prototype.init = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var httpClient, browser;
            var _this = this;
            return __generator(this, function (_a) {
                this.options = this.sdkOptions.init(options);
                httpClient = new http_client_1.HttpClient(this.options.apiURL, this.options.appKey, this.sdkUserAgent);
                this.apiClient.init(this.options.appKey, httpClient);
                this.processor.init(this.options);
                this.storage.init(this.options);
                browser = this.device.getBrowserInfo();
                this.storage.addDeviceKey('$browser', browser.getBrowserName());
                if (this.options.logBrowserEvents) {
                    this.registerBrowserEventsHandler.init();
                }
                if (this.options.logUIEvents) {
                    this.registerUIEventsHandler.init();
                }
                if (this.options.registerErrorHandler) {
                    this.registerErrorHandler.init(this);
                }
                if (this.options.overrideConsoleMethods) {
                    this.overrideConsoleMethods.init();
                }
                this.printToConsole.init(this.options.printToConsole);
                window.addEventListener('beforeunload', function () {
                    _this.processor.run();
                });
                return [2];
            });
        });
    };
    Bugfender.prototype.getDeviceURL = function () {
        return this.options.baseURL + "/intent/" + this.options.appID + "/device/" + this.device.getUDID();
    };
    Bugfender.prototype.getSessionURL = function () {
        return this.options.baseURL + "/intent/" + this.options.appID + "/session/" + this.session.getUUID();
    };
    Bugfender.prototype.getUserFeedback = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.userFeedback.show(options)];
            });
        });
    };
    Bugfender.prototype.log = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        this.storage.addLogMessage(log_1.LogLevel.Debug, __spreadArrays(parameters));
        (_a = this.printToConsole).log.apply(_a, parameters);
    };
    ;
    Bugfender.prototype.warn = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        this.storage.addLogMessage(log_1.LogLevel.Warning, __spreadArrays(parameters));
        (_a = this.printToConsole).warn.apply(_a, parameters);
    };
    ;
    Bugfender.prototype.error = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        this.storage.addLogMessage(log_1.LogLevel.Error, __spreadArrays(parameters));
        (_a = this.printToConsole).error.apply(_a, parameters);
    };
    ;
    Bugfender.prototype.trace = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        this.storage.addLogMessage(log_1.LogLevel.Trace, __spreadArrays(parameters));
        (_a = this.printToConsole).trace.apply(_a, parameters);
    };
    ;
    Bugfender.prototype.info = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        this.storage.addLogMessage(log_1.LogLevel.Info, __spreadArrays(parameters));
        (_a = this.printToConsole).info.apply(_a, parameters);
    };
    ;
    Bugfender.prototype.fatal = function () {
        var _a;
        var parameters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
        }
        this.storage.addLogMessage(log_1.LogLevel.Fatal, __spreadArrays(parameters));
        (_a = this.printToConsole).error.apply(_a, parameters);
    };
    ;
    Bugfender.prototype.removeDeviceKey = function (key) {
        this.storage.addDeviceKey(key, null);
        this.printToConsole.info("Device key \"" + key + "\" removed");
    };
    Bugfender.prototype.sendLog = function (log) {
        log.url = log.url || (window) ? window.location.toString() : '';
        this.storage.addLogEntry(log);
        this.printToConsole.printLog(log);
    };
    Bugfender.prototype.sendIssueMarkdown = function (title, markdown) {
        this.printToConsole.warn("Issue: " + title + ".\n" + markdown);
        return this.storage.addIssue(issue_1.IssueType.Issue, title, markdown);
    };
    Bugfender.prototype.sendIssue = function (title, text) {
        this.printToConsole.warn("Issue: " + title + ".\n" + text);
        var markdown = '```\n' + text + '\n```';
        return this.storage.addIssue(issue_1.IssueType.Issue, title, markdown);
    };
    Bugfender.prototype.sendCrash = function (title, text) {
        this.printToConsole.error("Crash: " + title + ".\n" + text);
        var markdown = '```\n' + text + '\n```';
        return this.storage.addIssue(issue_1.IssueType.Crash, title, markdown);
    };
    Bugfender.prototype.sendUserFeedback = function (title, markdown) {
        this.printToConsole.info("User Feedback: " + title + ".\n" + markdown);
        return this.storage.addIssue(issue_1.IssueType.Feedback, title, markdown);
    };
    Bugfender.prototype.setDeviceKey = function (key, value) {
        this.printToConsole.info("Device key \"" + key + "\" set to \"" + value + "\"");
        this.storage.addDeviceKey(key, value);
    };
    return Bugfender;
}());
exports.Bugfender = Bugfender;
