"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageWriterHub = void 0;
var storage_writer_sanitizer_1 = require("./storage-writer-sanitizer");
var util_1 = require("util");
var StorageWriterHub = (function () {
    function StorageWriterHub(callInfoFactory, channel, device, worker, session, uuidFactory, window) {
        this.callInfoFactory = callInfoFactory;
        this.channel = channel;
        this.device = device;
        this.worker = worker;
        this.session = session;
        this.uuidFactory = uuidFactory;
        this.window = window;
        this.sanitizer = new storage_writer_sanitizer_1.StorageWriterSanitizer();
    }
    StorageWriterHub.prototype.init = function (options) {
        var sessionUUID = this.session.getUUID();
        this.options = options;
        this.postMessage({
            type: 'init',
            data: { options: options, sessionUUID: sessionUUID },
        });
    };
    StorageWriterHub.prototype.postMessage = function (message) {
        try {
            this.worker.postMessage(this.sanitizer.sanitize(message));
        }
        catch (err) {
        }
    };
    StorageWriterHub.prototype.addDeviceKey = function (key, value) {
        if (typeof key !== 'string') {
            throw new Error("The device-key 'key' must be a string.");
        }
        this.device.setDeviceKey(key, value);
        this.postMessage({
            type: 'device-key',
            data: { key: key, value: value, callInfo: this.callInfoFactory.create() },
        });
    };
    StorageWriterHub.prototype.addLogEntry = function (log) {
        this.postMessage({
            type: 'log-entry',
            data: { log: log, callInfo: this.callInfoFactory.create() },
        });
    };
    StorageWriterHub.prototype.addLogMessage = function (level, parameters) {
        var log = {};
        var textFormat = parameters[0], textParams = parameters.slice(1);
        var text;
        try {
            textFormat = this.sanitizer.toString(textFormat);
        }
        catch (_a) {
            textFormat = util_1.format(textFormat);
        }
        if (textParams.length > 0) {
            text = util_1.format.apply(void 0, __spreadArrays([textFormat], textParams));
        }
        else {
            text = textFormat;
        }
        log.level = level;
        log.text = text;
        log.url = (this.window) ? this.window.location.toString() : null;
        this.addLogEntry(log);
    };
    StorageWriterHub.prototype.addIssue = function (type, title, markdown) {
        if (typeof title !== 'string') {
            throw new Error("The crash/issue/feedback 'title' must be a string.");
        }
        var uuid = this.uuidFactory.create();
        this.postMessage({
            type: 'issue',
            data: { type: type, title: title, markdown: markdown, uuid: uuid, callInfo: this.callInfoFactory.create() },
        });
        this.channel.notifyForceSend();
        return this.options.baseURL + "/intent/" + this.options.appID + "/issue/" + uuid;
    };
    return StorageWriterHub;
}());
exports.StorageWriterHub = StorageWriterHub;
