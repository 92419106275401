"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Device = void 0;
var Bowser = __importStar(require("bowser"));
var Device = (function () {
    function Device(localStorage, sdkVersion, uuidFactory) {
        this.localStorage = localStorage;
        this.sdkVersion = sdkVersion;
        this.uuidFactory = uuidFactory;
        this.UDID_KEY = 'bf_device_udid';
        this.DATA_KEY = 'bf_device_data';
    }
    Device.prototype.getBrowserInfo = function () {
        return Bowser.getParser(window.navigator.userAgent);
    };
    Device.prototype.getDeviceData = function () {
        var data = this.localStorage.getItem(this.DATA_KEY);
        if (data === null) {
            data = '{}';
        }
        return JSON.parse(data);
    };
    Device.prototype.getInfo = function (options) {
        var browser = this.getBrowserInfo();
        return {
            udid: this.getUDID(),
            name: browser.getBrowserName() + " " + browser.getBrowserVersion() + " on " + browser.getOSName() + " " + browser.getOSVersion(),
            device_type: '',
            firebase_test_lab: false,
            key_values: this.getDeviceData(),
            language: navigator.language || navigator['userLanguage'],
            os_version: browser.getOSVersion(),
            sdk_type: 'web',
            sdk_version: this.sdkVersion.toString(),
            build: options.build,
            version: options.version,
        };
    };
    Device.prototype.getUDID = function () {
        var udid = this.localStorage.getItem(this.UDID_KEY);
        if (udid === null) {
            udid = this.uuidFactory.create();
            this.localStorage.setItem(this.UDID_KEY, udid);
        }
        return udid;
    };
    Device.prototype.setDeviceKey = function (key, value) {
        var data = this.getDeviceData();
        if (value === null) {
            delete data[key];
        }
        else {
            data[key] = value;
        }
        this.localStorage.setItem(this.DATA_KEY, JSON.stringify(data));
    };
    return Device;
}());
exports.Device = Device;
