"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiClient = void 0;
var ApiClient = (function () {
    function ApiClient() {
    }
    ApiClient.prototype.init = function (appKey, http) {
        this.appKey = appKey;
        this.http = http;
    };
    ApiClient.prototype.getDeviceStatus = function (deviceInfo) {
        var payload = {
            app_token: this.appKey,
            device: deviceInfo,
        };
        return this.http.post('/app/device-status', payload).then(function (response) {
            var hasError = typeof response.error !== 'undefined';
            var isAppValid = !hasError || hasError && response.error.code !== -1004;
            var isDeviceEnabled = typeof response.logger_enabled !== 'undefined' ? response.logger_enabled : false;
            return { isDeviceEnabled: isDeviceEnabled, isAppValid: isAppValid };
        });
    };
    ApiClient.prototype.sendSession = function (session) {
        return this.http.post('/session', session);
    };
    ApiClient.prototype.sendLogs = function (sessionId, logs) {
        return this.http.post('/log/batch', {
            session_id: sessionId,
            logs: logs,
        });
    };
    ApiClient.prototype.sendIssue = function (uuid, issueURL, title, markdown, type, sessionId) {
        var payload = {
            uid: uuid,
            title: title,
            text: markdown,
            app: { key: this.appKey },
            type: type,
            session_id: sessionId,
        };
        return this.http.post('/issue', payload).then(function () {
            return { uuid: uuid, issueURL: issueURL };
        });
    };
    ApiClient.prototype.setDeviceKey = function (deviceId, key, value) {
        return this.http.post('/device/keyvalue', {
            app_key: this.appKey,
            device_id: deviceId,
            key: key,
            value: value,
        });
    };
    return ApiClient;
}());
exports.ApiClient = ApiClient;
