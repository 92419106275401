"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterBrowserEventsHandler = void 0;
var register_events_handler_base_1 = require("./register-events-handler.base");
var RegisterBrowserEventsHandler = (function (_super) {
    __extends(RegisterBrowserEventsHandler, _super);
    function RegisterBrowserEventsHandler(storage, window) {
        var _this = _super.call(this, window) || this;
        _this.storage = storage;
        _this.window = window;
        return _this;
    }
    RegisterBrowserEventsHandler.prototype.init = function () {
        var _this = this;
        if (!this.window.document.addEventListener) {
            return;
        }
        var options = this.supportsPassiveEvents() ?
            { passive: true, capture: true } :
            true;
        this.window.addEventListener('load', function (e) {
            _this.storage.addLogEntry({
                tag: 'js_event',
                text: JSON.stringify({
                    event: 'PageLoaded',
                    performance: _this.window.performance
                }),
                url: _this.getURL(),
            });
        }, options);
        this.window.addEventListener('DOMContentLoaded', function () {
            _this.storage.addLogEntry({
                tag: 'js_event',
                text: JSON.stringify({
                    event: 'DOMContentLoaded',
                }),
                url: _this.getURL(),
            });
        }, options);
        this.window.addEventListener('popstate', function (e) {
            _this.storage.addLogEntry({
                tag: 'js_event',
                text: JSON.stringify({
                    event: 'popstate',
                    location: _this.window.location.toString(),
                    state: JSON.stringify(e.state),
                }),
                url: _this.getURL(),
            });
        }, options);
        this.window.addEventListener('hashchange', function () {
            _this.storage.addLogEntry({
                tag: 'js_event',
                text: JSON.stringify({
                    event: 'hashchange',
                    hash: _this.window.location.hash,
                }),
                url: _this.getURL(),
            });
        }, options);
        var historyOverrideFnFactory = function (originalFn) {
            var self = _this;
            return function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var url = args.length > 2 ? args[2] : undefined;
                if (url) {
                    self.storage.addLogEntry({
                        tag: 'js_event',
                        text: JSON.stringify({
                            event: 'pushstate',
                            to: String(url),
                            state: args.length > 1 ? JSON.stringify(args[1]) : null
                        }),
                        url: self.getURL(),
                    });
                }
                return originalFn.apply(this, args);
            };
        };
        this.override(this.window.history, 'pushState', historyOverrideFnFactory);
        this.override(this.window.history, 'replaceState', historyOverrideFnFactory);
    };
    RegisterBrowserEventsHandler.prototype.override = function (object, method, overrideFactory) {
        if (!(method in object)) {
            return;
        }
        var original = object[method];
        var wrapped = overrideFactory(original);
        object[method] = wrapped;
    };
    return RegisterBrowserEventsHandler;
}(register_events_handler_base_1.RegisterEventsHandlerBase));
exports.RegisterBrowserEventsHandler = RegisterBrowserEventsHandler;
