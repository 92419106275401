"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDeferred = void 0;
function createDeferred() {
    var deferred = {
        isFulfilled: false,
        promise: undefined,
        resolve: undefined,
        reject: undefined,
    };
    deferred.promise = new Promise(function (resolve, reject) {
        deferred.resolve = function (value) {
            deferred.isFulfilled = true;
            resolve(value);
        };
        deferred.reject = function (reason) {
            deferred.isFulfilled = true;
            reject(reason);
        };
    });
    return deferred;
}
exports.createDeferred = createDeferred;
